<nav class="navbar navbar-expand">
  <div class="container-fluid">
    <a class="navbar-brand" href="/" alt="home"> <img src="favicon-32x32.png" alt="Home" /></a>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="/" alt="Home">Home</a>
      </li>
    </ul>
  </div>
</nav>

<style>
  .navbar {
    position: relative;
    z-index: 1;
    color: #000;
    background-color: #e7dfc6;
    opacity: 0.8;
    overflow: hidden;
  }

  .nav-link {
    float: right;
    color: #000;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
  }

  .nav-link:hover {
    color: #ff5d73;
  }
</style>
